import { React } from 'react';
import { Link } from 'react-router-dom';
import PinseekersText from '../img/pinseekers_text_white.svg'

function Navbar() {
    return (

        <div>
      <nav className='navbar'>

          <Link to='/'><img className='logo-img' src={PinseekersText} alt=""></img></Link>

          <ul className='nav'>
              <li><Link to='/home'>Home</Link></li>
              <li><Link to='/players'>Players</Link></li>
              <li><Link to='/results'>Results</Link></li>
            </ul>
      </nav>
      </div>

    );
  };

  <nav className='navbar'>
  <Link to='/' className='logo'>
    Pinseekers
  </Link>

  <ul className='nav'>
    <li>
      <Link to='/posts'>Posts</Link>
    </li>
    <li>
      <Link to='/results'>Results</Link>
    </li>
    <li>
      <Link to='/apply'>Apply</Link>
    </li>
  </ul>
</nav>
  
  export default Navbar;