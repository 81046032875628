function Contact() {
    return(
        <div className="container">
            <h1>Contact Us</h1>
            <p>Feel free to get in touch at jack@pinseekers.co.nz for any of the following:</p>
            <ul>
                <li>- Event sponorship or business enquiries</li>
                <li>- Applications to compete at the next Pinseeker Invitiational</li>
                <li>- Complaints about historical scores, or direct complaints to Pinseekers management (existing players)</li>
            </ul>
        </div>
    )
}

export default Contact