import Navbar from './components/Navbar';
import './index.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Home from './pages/Home';
import Contact from './pages/Contact';
import Players from './pages/Players';
import Results from './pages/Results';
import Rules from './pages/Rules';
import PlayerDetail from './pages/PlayerDetail';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <div>
        <Routes>        
          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="players" element={<Players />} />
          {/*Add route for player detail page*/}
            <Route path="players/:player" element={<PlayerDetail />} />
          <Route path="contact" element={<Contact />} />
          <Route path="results" element={<Results />} />
          <Route path="rules" element={<Rules />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
