import Signatures from "../img/signatures.png"

function Rules() {
    return(
        <div className="container">

            <h2>RULES OF PLAY</h2>
            <table className="table left">
                    <tbody>
                    <tr>
                        <td>Combined net score of both rounds decides your final score (i.e. number of strokes minus playing handicap).</td>
                    </tr>
                    <tr>
                        <td>Playing handicap will be locked in at the start of each contest, decided by your WHS handicap rounded to the nearest whole number.</td>
                    </tr>
                    <tr>
                        <td>Irish drops will play. Drops are to be taken from the knee in line with where the ball first enters out of bounds and where the player has tee’d off.</td>
                    </tr>
                    <tr>
                        <td>Gallery rules apply on a case by case basis, with all other players in the playing group required to agree with the decision.</td>
                    </tr>
                    <tr>
                        <td>1 mulligan is allowed for each player over the course of the weekend.</td>
                    </tr>
                    <tr>
                        <td>Intentionally tanking games leading up to a Pinseekers tournament will see you disqualified, as decided by all players.</td>
                    </tr>
                    </tbody>
                </table>

            <br/>

            <h2>TOURNAMENT POINTS</h2>
            <table className="table left">
                    <tbody>
                    <tr>
                        <td>The points pool for a tournament is made up of 100 points for each player entered.</td>
                    </tr>
                    <tr>
                        <td>1st through 8th places receive a % total of the points pool.</td>
                    </tr>
                    <tr>
                        <td>9th place receives 20 tournament points, with 10th place and above receiving 10 points.</td>
                    </tr>
                    </tbody>
                </table>

                <br/>

            <img className="signatures" src={Signatures} alt="Board Approval"></img>

                <br/>
                <br/>

        </div>
    )
}

export default Rules