import React from "react"

function Leaderboard() {
    return(
        <div>
            <h2 className="center">2022 Season Results</h2>

            <br/>

            <table className="table left">
                    <thead>
                        <tr>
                            <th>Place</th>
                            <th>Player</th>
                            <th>Points</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td><a onclick='gotoPlayer("marcuswilliams")' href="./players.html">Marcus Williams</a></td>
                            <td>380</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td><a onclick='gotoPlayer("fletcherboswell")' href="./players.html">Fletcher Boswell</a></td>
                            <td>350</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td><a onclick='gotoPlayer("willdavison")' href="./players.html">William Davison</a></td>
                            <td>295</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td><a onclick='gotoPlayer("jackryder")' href="./players.html">Jack Ryder</a></td>
                            <td>265</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td><a onclick='gotoPlayer("harrybarker")' href="./players.html">Harry Barker</a></td>
                            <td>225</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td><a onclick='gotoPlayer("zacgadsby")' href="./players.html">Zac Gadsby</a></td>
                            <td>185</td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td><a onclick='gotoPlayer("jacklarsen")' href="./players.html">Jack Larsen</a></td>
                            <td>185</td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td><a onclick='gotoPlayer("cameronmccoll")' href="./players.html">Cameron McCool</a></td>
                            <td>170</td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td><a onclick='gotoPlayer("jackleeming")' href="./players.html">Jack Leeming</a></td>
                            <td>70</td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td><a onclick='gotoPlayer("dannycloete")' href="./players.html">Danny Cloete</a></td>
                            <td>20</td>
                        </tr>
                    </tbody>
                </table>
        </div>

    )
}

export default Leaderboard