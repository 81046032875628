import React from "react";
import Pokerstars from '../img/pokerstars.jpg'
import Greyville from '../img/greyville_racecourse.png'
import Brothers from '../img/brothers_beer.png'

function Sponsors() {
    return(
        <div className="container center bg-white">
            <br />
            <img className="sponsor-img" src={Pokerstars} alt="sponsor" />
            <img className="sponsor-img" src={Greyville} alt="sponsor" />
            <img className="sponsor-img" src={Brothers} alt="sponsor" />
        </div>
    )
}

export default Sponsors