import ResultsData from "../data/results.json"
import Collapsible from "react-collapsible"


function Results() {
    const results = [ResultsData.results[0]]
    console.log(results)

    return(
        <div className="container">
            <h1>Results</h1>

            <br />

            <div className="results-list">
                
                {results.map(result => (
                    <Collapsible trigger={result.season}>
                        <table className="table left">
                            <thead>
                                <tr>
                                    <th>Place</th>
                                    <th>Player</th>
                                    <th>Points</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>{result.first.name}</td>
                                    <td>{result.first.points}</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>{result.second.name}</td>
                                    <td>{result.second.points}</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>{result.third.name}</td>
                                    <td>{result.third.points}</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>{result.fourth.name}</td>
                                    <td>{result.fourth.points}</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>{result.fifth.name}</td>
                                    <td>{result.fifth.points}</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>{result.sixth.name}</td>
                                    <td>{result.sixth.points}</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>{result.seventh.name}</td>
                                    <td>{result.seventh.points}</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>{result.eighth.name}</td>
                                    <td>{result.eighth.points}</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>{result.ninth.name}</td>
                                    <td>{result.ninth.points}</td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>{result.tenth.name}</td>
                                    <td>{result.tenth.points}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Collapsible>
                ))}
                <br />
            </div>    
        </div>        
    )
}

export default Results