import { Link } from "react-router-dom"
import PlayerData from "../data/players.json"

function Players() {
    const playerList = [PlayerData.players]
    const players = playerList[0]

    return(
        <div>
            <div className='container'>

                <div className='page-header'>
                    <h1>Players</h1>
                </div>

                <ul className='posts-list'>
                    {players.map(player => (
                        <li key={player.key}>
                            <Link to={player.key.toString()}>
                            <img className="player-list-img" src={player.picture} alt={player.lname}></img>
                            <h3 className="player-list-name">{player.fname} {player.lname}</h3>
                            <p className="player-list-handicap">Handicap: {player.handicap}</p>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>

            <br/>

        </div>
        
    )
}

export default Players