import Leaderboard from '../components/Leaderboard';
import Sponsors from '../components/Sponsors';
import Queenstown from '../img/queenstown.jpeg';

function Home() {
    return (
      <div>
          <img className="splash-img" alt="Golf Course" src={Queenstown} />
          <div className="splash-content">
            <h1>2022 Coromandel Invitational</h1>
            <p>May 7th | Onemana</p>
          </div>

        <div className='bg-grey'>
            <br/>
            <div className='container'>
                <Leaderboard />
            </div>
            <br/>
        </div>

        <Sponsors />
        
        <br/>

      </div>
    )
  }

export default Home