import PlayerData from "../data/players.json"
import { useParams } from "react-router-dom"

function PlayerDetail() {
    const params = useParams()
    console.log(params)
    const player = PlayerData.players[params.player]

    return(
        <div className="container">
            <div className="player-detail-header">
                <div className="player-detail-img-box">
                    <img className="player-detail-img" src={player.picture} alt={player.lname}></img>
                    <br />
                </div>

                <div className="player-detail-title-box">
                    <h1>{player.fname} {player.lname}</h1>
                    <h3>Handicap: {player.handicap}</h3>
                </div>
            </div>

            <div>
                <br />
            </div>

            <p>{player.bio1}</p>
            <p>{player.bio2}</p>
            <p>{player.bio3}</p>

            <br />

        </div>
    )
}

export default PlayerDetail